[_nghost-sc262] {
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-layout[_ngcontent-sc262] {
  height: 100vh;
}

.menu-sidebar[_ngcontent-sc262] {
  z-index: 10;
  box-shadow: 2px 0 6px #00152959;
  box-shadow: none;
  min-height: calc(100vh - 57px);
  margin: -4px 0;
  position: relative;
}

.header-trigger[_ngcontent-sc262] {
  cursor: pointer;
  height: 64px;
  padding: 20px 24px;
  font-size: 20px;
  transition: all .3s, padding;
}

.sidebar-logo[_ngcontent-sc262] {
  background: #001529;
  height: 64px;
  line-height: 64px;
  transition: all .3s;
  position: relative;
  overflow: hidden;
}

.sidebar-logo[_ngcontent-sc262] img[_ngcontent-sc262] {
  width: 32px;
  height: 32px;
}

.sidebar-logo[_ngcontent-sc262] h1[_ngcontent-sc262], .sidebar-logo[_ngcontent-sc262] img[_ngcontent-sc262] {
  vertical-align: middle;
  display: inline-block;
}

.sidebar-logo[_ngcontent-sc262] h1[_ngcontent-sc262] {
  color: #fff;
  margin: 0 0 0 20px;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

nz-header[_ngcontent-sc262] {
  z-index: 2;
  width: 100%;
  padding: 0 24px;
}

.app-header[_ngcontent-sc262] {
  background: #fff;
  height: 64px;
  padding: 0;
  position: relative;
  box-shadow: 0 1px 4px #00152914;
}

nz-content[_ngcontent-sc262] {
  margin: 24px;
}

.inner-content[_ngcontent-sc262] {
  background: #fff;
  height: 100%;
  padding: 24px;
}

.ant-layout-content[_ngcontent-sc262] {
  max-height: calc(100vh - 110px);
}

i[_ngcontent-sc262] {
  margin-right: 0 !important;
}

.ant-menu-item[_ngcontent-sc262] span[_ngcontent-sc262] {
  margin-left: 10px !important;
}

.sidebar-logo[_ngcontent-sc262] {
  background: #fff;
}

[_ngcontent-sc262] img[_ngcontent-sc262] {
  width: auto;
  height: 35px;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list, .slick-slider {
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list, .slick-slider .slick-track {
  transform: translateZ(0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:after, .slick-track:before {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid #0000;
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

* {
  box-sizing: border-box;
  margin: 0;
}

::selection {
  color: #f27107;
  background: none;
}

body {
  -ms-font-feature-settings: "lnum" 1;
  -o-font-feature-settings: "lnum" 1;
  font-feature-settings: "lnum" 1;
  color: #b3b3b3;
  background-color: #191919;
  font-family: Archivo Regular, sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-variant: none !important;
}

a:hover {
  color: inherit;
}

button {
  cursor: pointer;
  border: none;
}

button:focus {
  outline: none;
}

p {
  margin: 0;
}

section {
  max-width: 100vw;
  padding: 0 10vw;
}

.col--100 {
  width: 100%;
}

.col--50 {
  width: 50%;
}

.col--45 {
  width: 45%;
}

.col--30 {
  width: 30%;
}

.col--20 {
  width: 20%;
}

.mb--50 {
  margin-bottom: 50px;
}

.mt-20 {
  margin-top: 20px;
}

.absolute {
  position: absolute;
}

.slick-slide, .slick-slide[aria-hidden="true"]:not(.slick-cloned) ~ .slick-cloned[aria-hidden="true"] {
  transition: opacity .3s;
}

.slick-center, .slick-slide[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"] {
  opacity: 1;
}

.white {
  color: #e6e6e6;
}

.bg--white {
  background-color: #e6e6e6;
}

.light-grey {
  color: #b3b3b3;
}

.grey {
  color: gray;
}

.orange {
  color: #f27107;
}

.bg--orange {
  background-color: #f27107;
}

.black {
  color: #131313;
}

.uppercase {
  text-transform: uppercase;
}

.archivo-e_bold {
  font-family: Archivo Expanded Bold;
  font-weight: 700;
}

.archivo {
  font-family: Archivo Regular;
  font-weight: 400;
}

.archivo--black {
  font-family: Archivo Black;
  font-weight: 900;
}

.archivo--eb {
  font-family: Archivo ExtraBold;
  font-weight: 800;
}

b {
  font-family: Archivo Bold;
  font-weight: 700;
}

.section__title {
  color: #e6e6e6;
  font-family: Archivo Regular;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3;
  position: relative;
}

.f--14 {
  font-size: 14px;
}

.f--14, .f--16 {
  line-height: 1.2em;
}

.f--16 {
  font-size: 16px;
}

.f--18 {
  font-size: 18px;
  line-height: 1.5em;
}

.f--24 {
  font-size: 24px;
  line-height: 1.3em;
}

.f--32 {
  font-size: 32px;
  line-height: 1em;
}

.f--36 {
  font-size: 3vw;
}

.f--36, .f--40 {
  line-height: 1.1em;
}

.f--40 {
  font-size: 3.3vw;
}

.display--inline {
  display: block;
}

.section--flex {
  flex-wrap: wrap;
  display: flex;
}

.flex--jc-ac {
  justify-content: center;
}

.flex--jc-ac, .flex--js-ac {
  align-items: center;
  display: flex;
}

.flex--js-ac {
  justify-content: flex-start;
}

.flex--je-ae {
  justify-content: flex-end;
}

.flex--je-ae, .flex--js-ae {
  align-items: flex-end;
  display: flex;
}

.flex--js-ae, .flex--js-as {
  justify-content: flex-start;
}

.flex--js-as, .flex--jsb-as {
  align-items: flex-start;
  display: flex;
}

.flex--jsb-ac, .flex--jsb-as {
  justify-content: space-between;
}

.flex--jsb-ac {
  align-items: center;
  display: flex;
}

.mobile--only {
  display: none;
}

.desktop--only {
  display: block;
}

.text--vertical {
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

hr {
  border: 0;
  flex-basis: 100%;
  height: 0;
  margin: 0;
}

.header__select.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: none;
}

.header__select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #0000 !important;
  border: none !important;
}

.header__select.ant-select, .header__select .anticon, .homepage .header__select.ant-select, .homepage .header__select .anticon {
  color: #e6e6e6;
}

.header__select.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border: none;
}

.products .slick-list {
  overflow: inherit !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #0000;
  border: none;
}

.ant-checkbox-inner {
  border-radius: 0;
}

.ant-checkbox-checked .ant-checkbox-inner, .ant-checkbox-inner {
  background-color: #0000;
  border: 2px solid #e6e6e6;
}

.ant-checkbox-checked:after {
  border: none !important;
}

.ant-checkbox-input:focus + .ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
  border-color: #e6e6e6;
}

.slick-slide:focus {
  outline: none;
}

.ant-input, .ant-select {
  border-color: #e6e6e6 !important;
}

.ant-input-focused, .ant-input:focus {
  box-shadow: none;
  border-color: #e6e6e6;
  border-right-width: 3px !important;
}

.ant-select-dropdown {
  border-radius: 2px !important;
  padding: 0 !important;
  box-shadow: 1px 3px 12px #6464641a !important;
}

.ant-select-item-option, .ant-select-item-option-active:not(.ant-select-item-option-disabled), .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #131313;
  background-color: #e6e6e6;
}

.ant-select-item:hover {
  background-color: #b3b3b3;
}

.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  transition: none !important;
}

.cookie-policy .iub_content h2, .privacy-policy .iub_content h1 {
  color: #e6e6e6;
  font-family: Archivo Expanded Bold;
  line-height: 1.1;
}

.cookie-policy .iub_content h3, .privacy-policy .iub_content h2 {
  color: #e6e6e6;
  margin-top: 50px;
  font-family: Archivo Expanded Bold;
  font-size: 25px;
}

.cookie-policy .iub_content h4, .privacy-policy .iub_content h3 {
  margin-top: 25px;
  margin-bottom: 0;
  font-family: Archivo Expanded Bold;
  font-size: 20px;
}

.iub_content a {
  color: #e6e6e6;
}

.iub_content dl, .iub_content ol, .iub_content ul {
  list-style-type: circle;
}

a.iubenda-tp-btn.iubenda-cs-preferences-link {
  visibility: hidden;
  display: none !important;
}

button {
  background-color: none !important;
}

.browser--ie {
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  background-color: #131313;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.browser--ie.ie {
  opacity: 1;
  visibility: visible;
  display: flex;
}

.browser--ie .logo--ie {
  width: 150px;
  height: 150px;
}

.browser--ie p {
  text-align: center;
  width: 250px;
  margin-top: 20px;
}

.mbm {
  margin-bottom: inherit;
}

@media screen and (width <= 1024px) {
  section {
    width: 90vw;
    padding-left: 30px;
    padding-right: 30px;
  }

  .section--flex {
    flex-direction: column;
  }

  .col--20, .col--30, .col--45, .col--50 {
    width: 100%;
  }

  .f--14 {
    font-size: 14px;
  }

  .f--14, .f--16 {
    line-height: 1.2em;
  }

  .f--16 {
    font-size: 15px;
  }

  .f--18 {
    font-size: 16px;
    line-height: 1.5em;
  }

  .f--24 {
    font-size: 21px;
    line-height: 1.3em;
  }

  .f--32 {
    font-size: 28px;
    line-height: 1em;
  }

  .f--36 {
    font-size: 36px;
  }

  .f--36, .f--40 {
    line-height: 1.1em;
  }

  .f--40 {
    font-size: 40px;
  }

  .container__inner {
    width: 90vw !important;
  }

  .mobile--only {
    display: block;
  }

  .desktop--only {
    display: none !important;
  }

  .mbm {
    margin-bottom: 50px;
  }
}

@media screen and (width <= 768px) {
  .f--14, .f--18 {
    font-size: 14px;
  }

  .f--14, .f--16 {
    line-height: 1.2em;
  }

  .f--16 {
    font-size: 15px;
  }

  .f--18 {
    font-size: 16px;
    line-height: 1.5em;
  }

  .f--24 {
    font-size: 18px;
    line-height: 1.3em;
  }

  .f--32 {
    font-size: 28px;
    line-height: 1em;
  }

  .f--36 {
    font-size: 23px;
  }

  .f--36, .f--40 {
    line-height: 1.1em;
  }

  .f--40 {
    font-size: 32px;
  }
}

.stripe--1[_ngcontent-sc306] {
  z-index: 10003;
  cursor: pointer;
  grid-template-rows: 40vh 20vh 20vh 20vh;
  grid-template-columns: calc(5vw + 2px);
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
}

.stripe--1[_ngcontent-sc306] .stripe__item[_ngcontent-sc306]:first-child {
  padding-top: 35px;
}

.stripe__item[_ngcontent-sc306] {
  background-color: #0000;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
  display: flex;
}

.stripe__item[_ngcontent-sc306] p[_ngcontent-sc306] {
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
  font-size: 18px;
  transform: rotate(180deg);
}

.arrow--menu[_ngcontent-sc306] {
  fill: none;
  stroke: #b3b3b3;
  stroke-miterlimit: 10;
}

.hamburger[_ngcontent-sc306] {
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
}

.hamburger[_ngcontent-sc306] svg[_ngcontent-sc306] {
  width: 75%;
  max-width: 40px;
}

.menu-item[_ngcontent-sc306] {
  margin-right: 5px;
}

.stripe--1__content[_ngcontent-sc306] {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.stripe--2[_ngcontent-sc306] {
  z-index: 10002;
  width: 80vw;
  padding-left: 5vw;
  right: -75vw;
}

.close__layer[_ngcontent-sc306], .stripe--2[_ngcontent-sc306] {
  height: 100vh;
  position: fixed;
  top: 0;
}

.close__layer[_ngcontent-sc306] {
  z-index: 104;
  cursor: pointer;
  opacity: 0;
  background-color: #191919cc;
  width: 100vw;
  display: none;
  left: 0;
}

.stripe--status[_ngcontent-sc306] {
  width: 100%;
  height: 100%;
  padding: 60px 5vw 80px;
  position: relative;
  overflow-y: auto;
}

.stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] a[_ngcontent-sc306], .stripe--2[_ngcontent-sc306] .stripe--status2[_ngcontent-sc306] a[_ngcontent-sc306], .stripe--2[_ngcontent-sc306] .stripe--status3[_ngcontent-sc306] a[_ngcontent-sc306] {
  line-height: 1.8;
  transition: all .2s ease-in-out;
  display: block;
  position: relative;
}

.stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] svg[_ngcontent-sc306], .stripe--2[_ngcontent-sc306] .stripe--status2[_ngcontent-sc306] svg[_ngcontent-sc306], .stripe--2[_ngcontent-sc306] .stripe--status3[_ngcontent-sc306] svg[_ngcontent-sc306] {
  width: 40px;
}

.stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] svg.arrow--status1[_ngcontent-sc306], .stripe--2[_ngcontent-sc306] .stripe--status2[_ngcontent-sc306] svg.arrow--status1[_ngcontent-sc306], .stripe--2[_ngcontent-sc306] .stripe--status3[_ngcontent-sc306] svg.arrow--status1[_ngcontent-sc306] {
  width: 60px;
}

.stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] a[_ngcontent-sc306]:hover span[_ngcontent-sc306], .stripe--status2[_ngcontent-sc306] a[_ngcontent-sc306]:hover span[_ngcontent-sc306], .stripe--status3[_ngcontent-sc306] a[_ngcontent-sc306]:hover span[_ngcontent-sc306] {
  color: gray;
}

.stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] a[_ngcontent-sc306] span[_ngcontent-sc306], .stripe--status2[_ngcontent-sc306] a[_ngcontent-sc306] span[_ngcontent-sc306], .stripe--status3[_ngcontent-sc306] a[_ngcontent-sc306] span[_ngcontent-sc306] {
  transition: color .2s;
}

.stripe--status1[_ngcontent-sc306], .stripe--status2[_ngcontent-sc306], .stripe--status3[_ngcontent-sc306] {
  background-color: #ccc;
}

.stripe--status1[_ngcontent-sc306] .content__wrap[_ngcontent-sc306] {
  align-items: center;
  height: 100%;
  display: flex;
}

.stripe--status1[_ngcontent-sc306] a[_ngcontent-sc306] {
  margin-bottom: 20px;
}

.stripe--2[_ngcontent-sc306] .products__container[_ngcontent-sc306] {
  flex-direction: column;
  justify-content: center;
  height: 100%;
  display: flex;
}

.stripe--2[_ngcontent-sc306] .products__content[_ngcontent-sc306] {
  -moz-columns: 2;
  column-count: 2;
  width: 100%;
}

.stripe--status3[_ngcontent-sc306] .project-image[_ngcontent-sc306] {
  z-index: 0;
  opacity: .3;
  height: 50vh;
  position: absolute;
  top: 25%;
  right: -5vw;
}

.stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] .content--bottom[_ngcontent-sc306] a[_ngcontent-sc306] {
  margin-top: 15px;
  font-size: 16px;
  position: relative;
}

.content--bottom--anim[_ngcontent-sc306] {
  justify-content: space-between;
  width: 50%;
  display: flex;
}

.stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] .content--bottom--anim[_ngcontent-sc306] a[_ngcontent-sc306]:after {
  content: "";
  width: 0;
  height: 2px;
  margin-left: 5px;
  transition: all .3s ease-in-out;
  position: absolute;
  bottom: 5px;
}

.stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] .content--bottom--anim[_ngcontent-sc306] a[_ngcontent-sc306]:hover:after {
  width: 20px;
}

.stripe--2[_ngcontent-sc306] .content__wrap[_ngcontent-sc306] {
  padding-top: 50px;
  padding-bottom: 70px;
}

.stripe--2[_ngcontent-sc306] .content--bottom[_ngcontent-sc306] {
  justify-content: space-between;
  align-items: flex-end;
  width: 30vw;
  display: flex;
}

.stripe--2[_ngcontent-sc306] .content--top[_ngcontent-sc306] {
  width: 100%;
}

.stripe--status2[_ngcontent-sc306] a[_ngcontent-sc306], .stripe--status3[_ngcontent-sc306] a[_ngcontent-sc306] {
  display: block;
}

.flex[_ngcontent-sc306] {
  align-items: center;
  min-height: calc(100vh - 250px);
  display: flex;
  position: relative;
}

.stripe--status3[_ngcontent-sc306] .project-list__container[_ngcontent-sc306] {
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  display: flex;
  position: relative;
}

.stripe--2[_ngcontent-sc306] .stripe--status2[_ngcontent-sc306] img[_ngcontent-sc306] {
  width: 20vw;
  position: absolute;
  top: 0;
  left: 0;
}

.stripe--2[_ngcontent-sc306] .stripe--status2[_ngcontent-sc306] a[_ngcontent-sc306] {
  z-index: 13;
  position: relative;
}

.menu-line[_ngcontent-sc306] {
  fill: none;
  stroke: #999;
  stroke-miterlimit: 10;
  stroke-width: 2.83px;
}

.menu-line2[_ngcontent-sc306] {
  fill: none;
  stroke: #f27107;
  stroke-miterlimit: 10;
  stroke-width: 2.83px;
  stroke-dashoffset: 68.62px;
  stroke-dasharray: 0 999999;
}

svg.arrow--status1[_ngcontent-sc306] {
  position: absolute;
  bottom: 15px;
  right: 10vw;
}

.stripe--2[_ngcontent-sc306] .stripe--status2[_ngcontent-sc306] svg.arrow--status1[_ngcontent-sc306] {
  right: 10%;
}

.stripe--status3[_ngcontent-sc306] svg.arrow--status1[_ngcontent-sc306] {
  right: -10%;
}

@media screen and (width <= 1024px) {
  .stripe--1[_ngcontent-sc306] .stripe__item[_ngcontent-sc306]:first-child {
    background-color: #f27107;
    padding-top: 35px;
  }

  .hamburger[_ngcontent-sc306] span[_ngcontent-sc306] {
    width: 7vw;
  }

  .stripe--2[_ngcontent-sc306] {
    width: 100vw;
  }

  .stripe--1[_ngcontent-sc306] {
    grid-template-columns: calc(10vw + 2px);
  }

  .stripe--2[_ngcontent-sc306] {
    padding-left: 10vw;
    right: -90vw;
  }

  .stripe--status[_ngcontent-sc306] {
    padding: 60px 5vw 80px 10vw;
  }

  .stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] a[_ngcontent-sc306] {
    font-size: 50px;
  }

  .stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] .content--bottom[_ngcontent-sc306] a[_ngcontent-sc306] {
    font-size: 16px;
    line-height: 1.7;
  }

  .stripe--2[_ngcontent-sc306] .content__wrap[_ngcontent-sc306] {
    padding-top: 10px;
  }

  .stripe--2[_ngcontent-sc306] .stripe--status2[_ngcontent-sc306] .content__wrap[_ngcontent-sc306] div[_ngcontent-sc306] {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .stripe--status2[_ngcontent-sc306] a[_ngcontent-sc306], .stripe--status3[_ngcontent-sc306] a[_ngcontent-sc306] {
    font-size: 25px;
  }

  .stripe--2[_ngcontent-sc306] .content--bottom[_ngcontent-sc306] {
    width: 70vw;
  }

  .stripe__item[_ngcontent-sc306] p[_ngcontent-sc306] {
    font-size: 16px;
  }

  .arrow--menu[_ngcontent-sc306] {
    fill: none;
    stroke: #e6e6e6;
    stroke-miterlimit: 10;
  }

  .stripe--status3[_ngcontent-sc306] .project-image[_ngcontent-sc306] {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    right: 0;
  }
}

@media screen and (width >= 500px) and (width <= 767px) {
  .stripe--1[_ngcontent-sc306] {
    grid-template-rows: 30vh 35vh 35vh 20vh;
  }

  .stripe--2[_ngcontent-sc306] {
    padding-left: 59px;
  }

  .stripe--2[_ngcontent-sc306] .content--top[_ngcontent-sc306] {
    -moz-columns: 2;
    column-count: 2;
  }

  .content--top[_ngcontent-sc306] svg[_ngcontent-sc306], .products__content[_ngcontent-sc306] svg[_ngcontent-sc306], .project-list__container[_ngcontent-sc306] svg[_ngcontent-sc306], .stripe--status3[_ngcontent-sc306] .project-image[_ngcontent-sc306] {
    display: none;
  }

  .stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] a[_ngcontent-sc306] {
    font-size: 24px;
  }

  .stripe__item[_ngcontent-sc306] p[_ngcontent-sc306] {
    font-size: 12px;
  }

  .stripe--status[_ngcontent-sc306] {
    padding: 30px 5vw 0;
    overflow: scroll;
  }

  .stripe--1[_ngcontent-sc306] .stripe__item[_ngcontent-sc306]:first-child {
    padding-top: 15px;
  }

  .stripe--2[_ngcontent-sc306] .stripe--status2[_ngcontent-sc306] .content__wrap[_ngcontent-sc306] div[_ngcontent-sc306] {
    padding-top: 10px;
    padding-bottom: 30px;
  }

  .stripe--2[_ngcontent-sc306] .products__container[_ngcontent-sc306] {
    height: auto;
  }

  .stripe--status3[_ngcontent-sc306] .project-list__container[_ngcontent-sc306] {
    z-index: 1;
    position: relative;
    top: auto;
    transform: none;
  }
}

@media screen and (width <= 500px) {
  span.menu-item.f--40[_ngcontent-sc306] {
    font-size: 100%;
  }

  .stripe--1[_ngcontent-sc306] {
    grid-template-rows: 25vh 30vh 30vh 15vh;
  }

  .content--top[_ngcontent-sc306] svg[_ngcontent-sc306], .products__content[_ngcontent-sc306] svg[_ngcontent-sc306], .project-list__container[_ngcontent-sc306] svg[_ngcontent-sc306], .stripe--status3[_ngcontent-sc306] .project-image[_ngcontent-sc306] {
    display: none;
  }

  .stripe--2[_ngcontent-sc306] .stripe--status2[_ngcontent-sc306] .content__wrap[_ngcontent-sc306] div[_ngcontent-sc306], .stripe--2[_ngcontent-sc306] .stripe--status3[_ngcontent-sc306] .content__wrap[_ngcontent-sc306] div[_ngcontent-sc306] {
    -moz-columns: 1;
    column-count: 1;
  }

  .stripe--2[_ngcontent-sc306] .content__wrap[_ngcontent-sc306] {
    padding-bottom: 10px;
  }

  .stripe--status2[_ngcontent-sc306] a[_ngcontent-sc306], .stripe--status3[_ngcontent-sc306] a[_ngcontent-sc306] {
    font-size: 5.8vw;
  }

  .stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] a[_ngcontent-sc306] {
    font-size: 10vw;
  }

  .stripe--2[_ngcontent-sc306] .content--bottom[_ngcontent-sc306] {
    width: auto;
    display: block;
  }

  .stripe--status[_ngcontent-sc306] {
    padding-bottom: 60px;
  }

  .content--bottom--anim[_ngcontent-sc306] {
    flex-direction: column;
    width: 100%;
  }

  .stripe__item[_ngcontent-sc306] p[_ngcontent-sc306] {
    font-size: 16px;
  }

  .arrow--menu[_ngcontent-sc306] {
    fill: none;
    stroke: #e6e6e6;
    stroke-miterlimit: 10;
  }
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .stripe__item[_ngcontent-sc306] p[_ngcontent-sc306] {
    -ms-writing-mode: bt-lr;
    writing-mode: vertical-lr;
    text-transform: uppercase;
    font-size: 1.7vh;
    transform: rotate(180deg);
  }

  .stripe—1[_ngcontent-sc306] {
    z-index: 10003;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
  }

  .stripe—1[_ngcontent-sc306] .stripe__item[_ngcontent-sc306]:first-child {
    background-color: #fbfbfb;
    height: 40vh;
    padding-top: 35px;
  }

  .stripe—1[_ngcontent-sc306] .stripe__item[_ngcontent-sc306]:nth-child(2), .stripe—1[_ngcontent-sc306] .stripe__item[_ngcontent-sc306]:nth-child(3) {
    background-color: #e6e6e6;
    height: 20vh;
  }

  .stripe—1[_ngcontent-sc306] .stripe__item[_ngcontent-sc306]:nth-child(4) {
    background-color: #2b8ccc;
    height: 20vh;
  }

  .hamburger[_ngcontent-sc306] {
    justify-content: center;
    width: 100%;
    height: 10px;
    margin-bottom: 10px;
    display: flex;
  }
}

header[_ngcontent-sc307] .logo[_ngcontent-sc307] {
  z-index: 1;
  opacity: .3;
  transform-origin: 0 0;
  width: 150px;
  position: fixed;
  top: 50px;
  left: 10vw;
}

.logo[_ngcontent-sc307] .cls-1[_ngcontent-sc307] {
  fill: #e6e6e6;
}

.input-form[_ngcontent-sc307], .logo[_ngcontent-sc307] {
  z-index: 999;
  position: fixed;
}

header[_ngcontent-sc307] .input-form[_ngcontent-sc307] {
  top: 39px;
  right: 5vw;
}

@media screen and (width <= 1024px) {
  header[_ngcontent-sc307] .logo[_ngcontent-sc307] {
    left: 30px;
  }
}

@media screen and (width <= 768px) {
  header[_ngcontent-sc307] .input-form[_ngcontent-sc307] {
    top: 25px;
    right: 10vw;
  }

  header[_ngcontent-sc307] .logo[_ngcontent-sc307] {
    top: 30px;
  }
}

@media screen and (width <= 500px) {
  header[_ngcontent-sc307] .input-form[_ngcontent-sc307] {
    right: 10vw;
  }
}

.section--1[_ngcontent-sc314] {
  width: 100%;
  height: 100vh;
  min-height: 1000px;
  padding-top: 60px;
  padding-bottom: 100px;
  position: relative;
}

.line[_ngcontent-sc314] {
  width: 100%;
  height: 1px;
  margin: 40px 0;
}

.section--1[_ngcontent-sc314] .pannello[_ngcontent-sc314] {
  z-index: 10000;
  width: 30%;
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.section--1[_ngcontent-sc314] .pannello[_ngcontent-sc314] img[_ngcontent-sc314] {
  width: 100%;
}

.section__content[_ngcontent-sc314] {
  position: relative;
}

.section--2[_ngcontent-sc314], .section--3[_ngcontent-sc314], .section--5[_ngcontent-sc314] {
  padding-top: 30vh;
}

.section--2[_ngcontent-sc314] img[_ngcontent-sc314] {
  width: 100%;
}

.section--3[_ngcontent-sc314] .section--flex[_ngcontent-sc314] {
  align-items: stretch;
}

.section--3[_ngcontent-sc314] .section--flex[_ngcontent-sc314] .col--50[_ngcontent-sc314]:first-child {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.section--5[_ngcontent-sc314] {
  display: flex;
}

.section--5--left[_ngcontent-sc314] {
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.section--5--left[_ngcontent-sc314] p[_ngcontent-sc314] {
  color: #000;
  z-index: 9;
  margin-left: 10vw;
  font-size: 23vw;
  line-height: 1;
  transition: all .5s ease-in-out;
  position: relative;
}

.section--5--left[_ngcontent-sc314] p.animTextColor[_ngcontent-sc314] {
  color: #000;
  opacity: .2;
}

.section--5--right[_ngcontent-sc314] {
  padding-right: 10vw;
}

.section--5--left[_ngcontent-sc314] img[_ngcontent-sc314] {
  -o-object-fit: cover;
  object-fit: cover;
  opacity: .3;
  width: 130%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -10vw;
  transform: scale(1.05);
}

.section--5--right[_ngcontent-sc314] p[_ngcontent-sc314]:hover .f--32[_ngcontent-sc314] {
  color: #f27107;
}

.section--5--right[_ngcontent-sc314] p[_ngcontent-sc314] .f--32[_ngcontent-sc314] {
  transition: all .2s;
}

.section--5--right[_ngcontent-sc314] p[_ngcontent-sc314] {
  padding-bottom: 15px;
}

.grid[_ngcontent-sc314] {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
}

.grid__item[_ngcontent-sc314] {
  padding: 20px 2.5vw calc(40px + 10vw);
  position: relative;
}

.arrow-line--big[_ngcontent-sc314] {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 2.83px;
}

@media screen and (width <= 1024px) {
  .section--5[_ngcontent-sc314] {
    width: 90vw;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (width <= 768px) {
  .section--2[_ngcontent-sc314], .section--3[_ngcontent-sc314], .section--5[_ngcontent-sc314] {
    padding-top: 15vh;
  }

  .section--1[_ngcontent-sc314] {
    align-items: center;
    width: 90%;
    height: auto;
    min-height: auto;
    padding-top: 150px;
  }

  .section--1[_ngcontent-sc314] .pannello[_ngcontent-sc314] {
    width: 50%;
    position: relative;
    bottom: auto;
    left: auto;
    transform: none;
  }

  .section--2[_ngcontent-sc314], .section--3[_ngcontent-sc314] {
    width: 90%;
  }

  .section--2[_ngcontent-sc314] {
    padding-top: 50px;
  }

  .section--2[_ngcontent-sc314] .absolute[_ngcontent-sc314] {
    position: relative;
  }

  .section--5[_ngcontent-sc314] {
    display: block;
  }

  .section--5--left[_ngcontent-sc314] {
    overflow: unset;
    width: 90vw;
    height: auto;
  }

  .section--5--left[_ngcontent-sc314] p[_ngcontent-sc314] {
    margin-left: 0;
    font-size: 55vw;
  }

  .section--5--left[_ngcontent-sc314] img[_ngcontent-sc314] {
    width: 110%;
  }

  .section--5[_ngcontent-sc314] .section__subtitle[_ngcontent-sc314] {
    white-space: nowrap;
    left: auto;
    right: 10vw;
  }

  .section--5--right[_ngcontent-sc314] {
    width: 90vw;
    margin-top: 20px;
    padding-top: 50px;
    padding-bottom: 0;
  }

  .section--5--right[_ngcontent-sc314] div[_ngcontent-sc314] {
    width: 70vw;
    display: block;
  }

  .dash[_ngcontent-sc314] {
    display: none;
  }

  .section--5--right[_ngcontent-sc314] p[_ngcontent-sc314] {
    font-size: 5vw;
    line-height: 1.8;
  }

  .section--5--right[_ngcontent-sc314] app-link[_ngcontent-sc314] {
    bottom: 50px;
  }
}

@media screen and (width <= 500px) {
  .section--10--bottom[_ngcontent-sc314] img[_ngcontent-sc314] {
    width: 70%;
  }
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .section—1—left[_ngcontent-sc314] .counter[_ngcontent-sc314] {
    width: 100vw;
    height: 16vw;
    margin-left: -3.5vw;
    padding-right: 30px;
    display: flex;
    position: absolute;
    top: 20vw;
    overflow: hidden;
  }

  .link__wrap[_ngcontent-sc314] {
    height: 35px;
  }

  .section__subtitle[_ngcontent-sc314] {
    -ms-writing-mode: bt-lr;
    writing-mode: vertical-lr;
    font-size: 16px;
    font-weight: 800;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(180deg);
  }
}

p[_ngcontent-sc309] {
  color: #e6e6e6;
  font-family: Archivo Bold;
  font-size: 16px;
}

.link-line1[_ngcontent-sc309], .link-line2[_ngcontent-sc309] {
  fill: none;
  stroke: #f27107;
  stroke-miterlimit: 10;
  stroke-width: 2.83px;
}

svg[_ngcontent-sc309] {
  width: 130px;
}

a.container[_ngcontent-sc309] {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

@media screen and (width <= 1024px) {
  a.container[_ngcontent-sc309] {
    align-items: flex-end;
  }
}

.section--10--top[_ngcontent-sc310] {
  padding: 200px 10vw;
}

.section--10--bottom[_ngcontent-sc310] {
  grid-template-columns: repeat(2, 1fr);
  height: 50vh;
  display: grid;
  overflow: hidden;
}

.section--10--bottom[_ngcontent-sc310] .grid__item[_ngcontent-sc310] {
  align-items: flex-end;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.section--10--bottom[_ngcontent-sc310] .bg__img[_ngcontent-sc310] {
  opacity: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: all .5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1);
}

.section--10--bottom[_ngcontent-sc310] .grid__item[_ngcontent-sc310]:hover .bg__img[_ngcontent-sc310] {
  opacity: .5;
  transform: scale(1.1);
}

.section--10[_ngcontent-sc310] .grid__item[_ngcontent-sc310] h3[_ngcontent-sc310] {
  transition: all .3s;
}

.section--10[_ngcontent-sc310] .grid__item[_ngcontent-sc310]:hover h3[_ngcontent-sc310] {
  color: #fff;
}

.section--10[_ngcontent-sc310] .item__content[_ngcontent-sc310] {
  margin-left: 10vw;
  padding-bottom: 30px;
}

.section--10--bottom[_ngcontent-sc310] svg[_ngcontent-sc310] {
  z-index: 9;
  width: 15vw;
  margin-top: -35px;
  margin-left: 5vw;
  position: relative;
}

.arrow-line--big[_ngcontent-sc310] {
  fill: none;
  stroke: #f27107;
  stroke-miterlimit: 10;
  stroke-width: 2.83px;
}

@media screen and (width <= 1024px) {
  .arrow-line--big[_ngcontent-sc310] {
    stroke-width: 6px;
  }

  .section--10--bottom[_ngcontent-sc310] svg[_ngcontent-sc310] {
    width: 80px;
    margin-top: 0;
  }

  .section--10--top[_ngcontent-sc310] {
    width: 90vw;
    margin-left: 0;
    padding: 150px 30px 50px;
  }

  .section--10--bottom[_ngcontent-sc310] {
    width: 90vw;
    height: auto;
    padding-left: 30px;
    padding-right: 30px;
    display: block;
  }

  .section--10[_ngcontent-sc310] .grid__item[_ngcontent-sc310] h3[_ngcontent-sc310] {
    text-align: left;
    font-size: 22px;
  }

  .section--10[_ngcontent-sc310] .grid__item[_ngcontent-sc310] p[_ngcontent-sc310] {
    text-align: right;
    font-family: Archivo Bold;
    font-size: 16px;
  }

  .section--10[_ngcontent-sc310] .item__content[_ngcontent-sc310] {
    align-items: flex-end;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-top: 30px;
    padding-bottom: 100px;
  }

  .section--10[_ngcontent-sc310] .item__content[_ngcontent-sc310] div[_ngcontent-sc310] {
    width: 100%;
  }

  .section--10--bottom[_ngcontent-sc310] img[_ngcontent-sc310] {
    width: 50%;
  }

  .section--10--bottom[_ngcontent-sc310] a[_ngcontent-sc310] {
    width: 100%;
  }
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .section--10--bottom[_ngcontent-sc310] {
    justify-content: space-between;
    height: 50vh;
    display: -ms-flexbox;
    overflow: hidden;
  }

  .section--10--bottom[_ngcontent-sc310] .grid__item[_ngcontent-sc310] {
    align-items: flex-end;
    width: 50%;
    padding: 0;
    display: flex;
    position: relative;
    overflow: hidden;
  }
}

.section--11[_ngcontent-sc311] {
  justify-content: space-between;
  padding-top: 200px;
  padding-bottom: 200px;
}

@media screen and (width <= 1024px) {
  .section--11[_ngcontent-sc311] {
    width: 90vw;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

footer[_ngcontent-sc312] {
  background-color: #191919;
  background-image: url("https://ik.imagekit.io/7pj6eu6xf/footer-map2.dd30896ea919aca1837c.png?updatedAt=1682314726393");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0 10vw;
}

h4[_ngcontent-sc312] {
  font-size: 20px;
}

footer[_ngcontent-sc312] p[_ngcontent-sc312] {
  font-size: 14px;
}

a[_ngcontent-sc312] {
  color: #fff;
}

.grid[_ngcontent-sc312] {
  grid-template-columns: repeat(2, 1fr);
  width: 30vw;
  margin-top: 50px;
  display: grid;
}

.content[_ngcontent-sc312] {
  padding: 50px 0 20px;
}

.content[_ngcontent-sc312] a[_ngcontent-sc312] {
  margin-bottom: 10px;
  font-family: Archivo Bold;
  font-size: 14px;
  display: block;
}

.line[_ngcontent-sc312] {
  background-color: #f27107;
  width: 100%;
  height: 1px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.subfooter[_ngcontent-sc312] {
  justify-content: space-between;
  padding: 0 0 50px;
  display: flex;
}

.subfooter[_ngcontent-sc312] a[_ngcontent-sc312], .subfooter[_ngcontent-sc312] p[_ngcontent-sc312] {
  font-size: 12px;
}

@media screen and (width <= 1024px) {
  footer[_ngcontent-sc312] {
    background-position: 50%;
    background-size: cover;
    width: 100vw;
    padding: 0 30px;
  }

  .grid[_ngcontent-sc312] {
    width: 100%;
  }

  .content[_ngcontent-sc312] {
    padding-top: 0;
  }
}

@media screen and (width <= 768px) {
  .subfooter[_ngcontent-sc312] {
    padding: 20px 0 80px;
    display: block;
  }

  .line[_ngcontent-sc312], .subfooter[_ngcontent-sc312] p[_ngcontent-sc312] {
    margin-bottom: 10px;
  }
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .grid[_ngcontent-sc312] {
    justify-content: space-between;
    display: -ms-flexbox;
  }
}

.loader[_ngcontent-sc313] {
  z-index: 100000;
  background-color: #191919;
  flex-direction: column;
  justify-content: flex-end;
  width: 100vw;
  height: 100vh;
  padding: 10vw;
  display: flex;
  position: absolute;
  top: 0;
}

.loading-counter__container[_ngcontent-sc313] {
  text-align: right;
  width: 100%;
}

span[_ngcontent-sc313] {
  display: block;
}

.loading-line[_ngcontent-sc313] {
  padding: 30px 0;
}

.loading-line[_ngcontent-sc313] .cls-1[_ngcontent-sc313], .loading-line[_ngcontent-sc313] .cls-2[_ngcontent-sc313] {
  fill: none;
  stroke-miterlimit: 10;
}

.loading-line[_ngcontent-sc313] .cls-1[_ngcontent-sc313] {
  stroke: #282828;
  stroke-width: 10px;
}

.loading-line[_ngcontent-sc313] .cls-2[_ngcontent-sc313] {
  stroke: #f27107;
  stroke-width: 10px;
}

@media screen and (width <= 1024px) {
  .loading-line[_ngcontent-sc313] svg[_ngcontent-sc313] {
    width: 100%;
    height: 1px;
  }

  p[_ngcontent-sc313] {
    margin-right: 2vw;
  }

  .loader[_ngcontent-sc313] {
    padding: 20vh 30px;
  }
}
/*# sourceMappingURL=index.370199af.css.map */
